// This is the homepage.

import React from "react"
// import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "typeface-averia-serif-libre"



const Heading1 = styled.h1`
    font-size: 1.8em;
    padding: 10px;
    text-align: center;
    width: 100%;
    min-height: 85px;
    margin: auto;
    font-weight:600;
  
`

const Wrapper = styled.div`
    width: 100%;
    background-position: center;
    padding: 10px;
    padding: 40px 40px 40px 40px;
    

`

const ContentWrapper = styled.div`
    text-align: center;
    padding: 20px 10px 20px 10px;
    margin: auto;
`

const Content = styled.p`
    width: auto;
    font-size: 1.3em;
    line-height: 30px;
    text-align: center;
    padding: 20px 0px 20px 0px;

`


class BlogIndex extends React.Component {
  render() {

    return (

      <Layout location={this.props.location}>
        <SEO title="Wholesale" />
         <Wrapper><Heading1>Machine</Heading1>
         <ContentWrapper>
            <Content> Bailey Coffee uses and sells selected bespoke commercial espresso equipment, which we maintain for our coffee customers by appointment.

</Content>
            <Content> </Content>
            <Content> </Content>
            <Content> </Content>
            <Content> </Content>
            <Content> </Content>
            <Content> </Content>
            </ContentWrapper>
          </Wrapper>
      </Layout>
    )
  }
}

export default BlogIndex